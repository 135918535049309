import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      MainView: {
        title: "Landpref",
        description: "Please score the landscape components to create your preferred future landscape for your local area. Use 0 for your lowest priority/priorities and 5 for your highest priorities. Note that each level corresponds to the spatial expansion in the landscape (i.e. Level 1: 10%, Level 2: 20%, Level 3: 40%, Level 4: 60%, Level 5: 80%). The total spatial expansion may not exceed 100%, so some compromises or trade-offs may be necessary.",
        note: " Please note that at the end of the exercise, if the selection of landscape components does not reach a total of 100%, all remaining space is going to be added to “biodiversity” as land lying fallow inevitably will provide habitat for insects, wildlife and natural vegetation.",
        continueButtonLabel: "Continue",
        continueAlertTitle: "Finished?",
        continueAlertText_100perc: "Are you finished with your land use allocation?",
        continueAlertText_below100perc: "You did not use 100% of the land. You can either edit the landscape to represent your preferences or the remaining space will be added to the land use \"biodiversity\".",
        continueAlertYes: "Yes",
        continueAlertChange: "No, I want to change something",
        selectAtLeastOneText: "Please select your desired landscape",
        selectAtLeastOneTitle: "Error",
      },
      Warnings: {
        minBiodiversity: "Please note that this land use accommodates habitats for birds, insects and different plants. Biodiversity will automatically adjust to level 1, if you change any of the other land uses from 0 to a higher level.",
        above100Perc: "You cannot choose this level because it exceeds the expansion of 100%. Please lower one of the other levels according to your preferences and within the spatial contingency."
      },
      DimensionNames: {
        farmland: "Farmland",
        renewable: "Renewable Energies",
        agroforestry: "Agroforestry",
        biodiversity: "Biodiversity",
      },
      DimensionExplanations: {
        agroforestry: "Agroforestry here combines the growth of trees and crops on the same area of land. This land use provides various environmental benefits, as they contribute to climate change adaptation and mitigation by storing carbon in the trunk, branches and roots, and protect the soil while enhancing biodiversity by providing habitats for insects, birds and other wildlife.",
        agriculture: "Agricultural land in this scenario is managed as conventional farmland with an average input of fertilisers and pesticides. The soil management practices include tillage. The conversion of a natural land cover to farmland has great negative impact on the biodiversity of a landscape because mechanical pressures and the application of agro-chemicals compromise habitats and food sources for insects, birds, and other wildlife.",
        biodiversity: "Natural landscape elements such as flowering field margins, solitaire trees and hedgerows create habitats for farmland birds, insects and wildlife. These landscape elements provide food and shelter for plants, insects, birds and small mammals throughout the year.",
        renewable: "The landscape components to provide renewable energy in this scenario are represented through solar panels and wind turbines, underneath which the soil is covered by natural grassland. The provision of renewable energy has many potential benefits, such as the reduction in greenhouse gas emissions, a reduced dependency on fossil fuels, and the creation of jobs in the area. Undisturbed grassland underneath the solar panels has a positive effect on (soil) biodiversity. Renewable energy technologies and other land uses may be compatible. Here, for simplification, please indicate your preferences under the assumption of spatial exclusion.",
      },
      GroundView: {
        title: "Soil biodiversity",
        text: "Soils and soil biodiversity are made up of millions of microbial and animal species such as bacteria, fungi, beetles and earth worms. The intensification of different land uses has different effects on soils and soil biodiversity. For instance, in agriculture, increasing mechanical pressures and use of fertilizers exert strong negative effects on soil biodiversity and functioning. In contrast, increasing (above-ground) biodiversity and associated landscape elements have been found to have positive effects.",
        choiceEffect_negative: "Your choice of landscape components has a negative impact on soil biodiversity.",
        choiceEffect_slightlypositive: "Your choice of landscape components has a slightly positive impact on soil biodiversity.",
        choiceEffect_positive: "Your choice of landscape components has a positive impact on soil biodiversity.",
        cantGoBackHeader: "You cannot go back",
        cantGoBackText: "You cannot change your landscape preferences anymore. Please proceed to the next questions.",
        continueToSurveyButton: "Continue",
      }
    }
  },
  es: {
    translation: {
      MainView: {
        title: "Landpref",
        description: "Puntúe los distintos componentes del paisaje para crear el paisaje futuro ideal para su región. Asigne 0 a los de prioridad mínima y 5 a los de prioridad máxima. Tenga en cuenta que cada nivel corresponde al porcentaje de superficie que ocupa en el paisaje (es decir, Nivel 1: 10 %, Nivel 2: 20 %, Nivel 3: 40 %, Nivel 4: 60 %, Nivel 5: 80 %). La superficie ocupada total no puede superar el 100 %, por lo que puede ser necesario realizar alguna concesión o algún intercambio.",
        note: "Tenga en cuenta que si, una vez completado el ejercicio, la selección de componentes del paisaje no alcanza un total de 100 %, el espacio restante se asignará a \"biodiversidad\", ya que la tierra sin cultivar servirá inevitablemente de hábitat para la vegetación natural, los insectos y el resto de la  fauna salvaje.",
        continueButtonLabel: "Continuar",
        continueAlertTitle: "Terminado?",
        continueAlertText_100perc: "¿Ha terminado de asignar el uso del suelo?",
        continueAlertText_below100perc: "No ha utilizado el 100% del terreno. Puedes editar el paisaje para que represente tus preferencias o el espacio restante se añadirá al uso del terreno \"biodiversidad\".",

        continueAlertYes: "Sí",
        continueAlertChange: "No, quiero cambiar algo",
        selectAtLeastOneText: "Seleccione el paisaje que desee",
        selectAtLeastOneTitle: "Error",
      },
      Warnings: {
        minBiodiversity: "Tenga en cuenta que este uso de la tierra alberga hábitats para aves, insectos y diferentes tipos de plantas. La porción de biodiversidad se ajustará automáticamente al nivel 1 si cambia cualquiera de los otros usos de la tierra de 0 a un nivel superior.",
        above100Perc: "No puede seleccionar este nivel porque supera la superficie ocupada total del 100 %. Reduzca alguno de los demás niveles en función de sus preferencias y dentro de los límites de espacio."
      },
      DimensionNames: {
        farmland: "Tierra cultivable",
        renewable: "Energías renovables",
        agroforestry: "Silvicultura",
        biodiversity: "Biodiversidad",
      },
      DimensionExplanations: {
        agroforestry: "En este caso, la silvicultura combina la plantación de árboles y de cultivos en la misma superficie de tierra. Este uso de la tierra ofrece diversos beneficios para el medio ambiente, ya que los árboles contribuyen a la adaptación al cambio climático y a la atenuación del mismo a través del almacenaje de carbono en el tronco, las ramas y las raíces. Además, protegen el suelo e incrementan la biodiversidad al proporcionar hábitats para insectos, aves y otras fauna y flora salvajes.",
        agriculture: "La tierra para uso agrícola en esta situación hipotética se gestiona como tierra cultivable convencional, utilizando una cantidad promedio de fertilizantes y pesticidas. Entre las prácticas de gestión del suelo se incluye el cultivo. La conversión de una cubierta de tierra natural en tierra cultivable tiene un enorme impacto negativo en la biodiversidad del suelo de un paisaje, debido a las presiones mecánicas y los productos agroquímicos que se aplican, que ponen en peligro a los hábitats y a las fuentes de alimento de insectos, aves y al resto de la el fauna y flora salvajes.",
        biodiversity: "Los elementos naturales del paisaje, como los márgenes de los campos florecidos, los árboles individuales y los setos crean hábitats para las aves, los insectos y el resto de la  fauna y flora salvajes. Estos elementos del paisaje proporcionan alimento y cobijo para plantas, insectos, aves y pequeños mamíferos durante todo el año.",
        renewable: "Los componentes del paisaje que aportan energía renovable en esta situación hipotética son los paneles solares y las turbinas de viento, debajo de los cuales el suelo está cubierto por pasto natural. La generación de energía renovable presenta numerosas ventajas, como la reducción de las emisiones de gases de efecto invernadero, una menor dependencia de los combustibles fósiles y la creación de empleo en la zona. El pasto inalterado que queda bajo los paneles solares tiene un efecto positivo sobre la biodiversidad (del suelo). Las tecnologías de energía renovable pueden ser compatibles con otros usos del suelo. Aquí, para simplificar, indique sus preferencias suponiendo que no son compatibles en el mismo espacio.",
      },
      GroundView: {
        title: "Biodiversidad del suelo",
        text: "Los suelos y la biodiversidad del suelo están compuestos por millones de especies de microorganismos y animales como bacterias, hongos, escarabajos o lombrices. Los diferentes usos intensivos de la tierra presentan distintos efectos sobre el suelo y la biodiversidad del mismo. Por ejemplo, en agricultura, la aplicación de presiones mecánicas cada vez mayores y el uso de fertilizantes generan importantes efectos negativos sobre la biodiversidad y el funcionamiento del suelo. Por el contrario, se ha demostrado que el aumento de la biodiversidad (por encima del suelo) y de los elementos del paisaje asociados tiene efectos positivos.",
        choiceEffect_negative: "Su elección de componentes del paisaje tiene un impacto negativo sobre la biodiversidad del suelo.",
        choiceEffect_slightlypositive: "Su elección de componentes del paisaje tiene un ligeramente positivo sobre la biodiversidad del suelo.",
        choiceEffect_positive: "Su elección de componentes del paisaje tiene un impacto positivo sobre la biodiversidad del suelo.",
        cantGoBackHeader: "No puedes volver atrás",
        cantGoBackText: "Ya no puede cambiar sus preferencias de paisaje. Por favor, continúe con las siguientes preguntas.",
        continueToSurveyButton: "Continuar",
      }
    }
  },
  da: {
    translation: {
      MainView: {
        title: "Landpref",
        description: "Giv landskabskomponenterne point for at skabe dit foretrukne fremtidige landskab for dit lokalområde. Giv 0 for din laveste prioritet/prioriteter og 5 for dine højeste prioriteter. Bemærk, at hvert niveau svarer til den rumlige dækning i landskabet (dvs. niveau 1: 10%, niveau 2: 20%, niveau 3: 40%, niveau 4: 60%, niveau 5: 80%). Den samlede rumlige dækning må ikke overstige 100 %, så det kan være nødvendigt med kompromiser eller afvejninger.",
        note: "Bemærk, at hvis udvælgelsen af landskabskomponenter ved øvelsens afslutning ikke er 100% i alt, vil al resterende plads blive tilføjet til \"biodiversitet\", idet braklagt jord vil give levesteder for insekter, dyreliv og naturlig vegetation.",
        continueButtonLabel: "Fortsæt",
        continueAlertTitle: "Færdig?",
        continueAlertText_100perc: "Er du færdig med din arealfordeling?",
        continueAlertText_below100perc: "Du har ikke brugt 100% af arealet. Du kan enten redigere landskabet, så det repræsenterer dine præferencer, eller den resterende plads vil blive føjet til arealanvendelsen \"biodiversitet\".",
        continueAlertYes: "Ja",
        continueAlertChange: "Nej, jeg vil gerne ændre noget",
        selectAtLeastOneText: "Vælg venligst dit ønskede landskab",
        selectAtLeastOneTitle: "Error",
      },
      Warnings: {
        minBiodiversity: "Bemærk, at denne arealanvendelse rummer levesteder for fugle, insekter og forskellige planter. Biodiversitet justeres automatisk til niveau 1, hvis du ændrer en af de andre arealanvendelser fra 0 til et højere niveau.",
        above100Perc: "Du kan ikke vælge dette niveau, fordi det overskrider den rumlige dækning på 100%. Reducer venligst et af de andre niveauer i henhold til dine præferencer og inden for de rumlige grænser."
      },
      DimensionNames: {
        farmland: "Landbrugsjord",
        renewable: "Vedvarende energi",
        agroforestry: "Skovlandbrug",
        biodiversity: "Biodiversitet",
      },
      DimensionExplanations: {
        agroforestry: "Skovlandbrug kombinerer her dyrkning af træer og afgrøder på det samme landområde. Denne arealanvendelse giver forskellige miljømæssige fordele, da det bidrager til tilpasning til og afbødning af klimaforandringer ved at lagre kulstof i stammer, grene og rødder, samt beskytter jorden samtidig med, at det forbedrer biodiversiteten ved at tilvejebringe levesteder for insekter, fugle og andet dyreliv.",
        agriculture: "Landbrugsjord i dette scenarie forvaltes som konventionel landbrugsjord med en gennemsnitlig anvendelse af gødning og pesticider. Jordforvaltningspraksisser inkluderer dyrket jord. Omdannelsen af naturligt jorddække til landbrugsjord har stor negativ indvirkning på jordbundens biodiversitet, idet mekanisk tryk og anvendelse af landbrugskemikalier kompromitterer levesteder og fødekilder for insekter, fugle og andet dyreliv.",
        biodiversity: "Naturlige landskabselementer som blomstrende markskel, individuelle træer og levende hegn skaber levesteder for agerlandets fugle, insekter og dyreliv. Disse landskabselementer tilvejebringer føde og ly for planter, insekter, fugle og små pattedyr hele året rundt.",
        renewable: "De landskabskomponenter, der skal levere vedvarende energi i dette scenarie, er repræsenteret ved solpaneler og vindmøller, og nedenunder er jorden dækket af naturlige græsarealer. Levering af vedvarende energi har mange potentielle fordele, såsom reduktion af drivhusgasemissioner, reduceret afhængighed af fossile brændstoffer og skabelse af arbejdspladser i området. Uforstyrrede græsarealer under solpanelerne har en positiv effekt på (jord)biodiversiteten. Vedvarende energiteknologier og andre arealanvendelser kan være forenelige. For nemhedens skyld bedes du her angive dine præferencer under antagelse af rumlig udelukkelse.",
      },
      GroundView: {
        title: "Soil biodiversity",
        text: "Jordbunden og dens biodiversitet består af millioner af mikrobielle og animalske arter såsom bakterier, svampe, biller og regnorme. Intensiveringen af forskellige arealanvendelser har forskellige effekter på jordbunden og dens biodiversitet. I landbruget har det stigende mekaniske tryk og brugen af kunstgødning f.eks. en stærkt negativ indvirkning på jordbundens biodiversitet og funktion. Modsat har øget biodiversitet (over jorden) og tilknyttede landskabselementer vist sig at have positive virkninger.",
        choiceEffect_negative: "Dit valg af landskabskomponenter har en negativ indvirkning på jordbundsbiodiversitet.  ",
        choiceEffect_slightlypositive: "Dit valg af landskabskomponenter har en let positiv indvirkning på jordbundsbiodiversitet.  ",
        choiceEffect_positive: "Dit valg af landskabskomponenter har en positiv indvirkning på jordbundsbiodiversitet.  ",
        cantGoBackHeader: "Du kan ikke gå tilbage",
        cantGoBackText: "Du kan ikke længere ændre dine landskabsindstillinger. Fortsæt venligst til de næste spørgsmål.",
        continueToSurveyButton: "Fortsæt",
      }
    }
  },
  ie: {
    translation: {
      MainView: {
        title: "Landpref",
        description: "Scóráil na comhchodanna tírdhreacha le do thoil chun an tírdhreach ba rogha leat amach anseo a chruthú don réigiún agat. Úsáid 0 don tosaíocht/na tosaíochtaí is ísle agat agus 5 do na tosaíochtaí is airde agat. Nótáil go gcomhfhreagraíonn gach aon leibhéal leis an gcumhdach spásúil sa tírdhreach (i.e. Leibhéal 1: 10%, Leibhéal 2: 20%, Leibhéal 3: 40%, Leibhéal 4: 60%, Leibhéal 5: 80%). Ní ceadaithe don chumhdach spásúil bheith de bhreis ar 100%, agus mar sin tharlódh go mba ghá roinnt comhghéillte nó trádmhalartuithe.",
        note: "Nótáil le do thoil gurb amhlaidh ag deireadh an taisc, más rud é nach ionann an roghnú de chomhchodanna tírdhreacha agus 100%, cuirfear an spás fágtha go léir isteach le “bithéagsúlacht” mar go mbeidh talamh fágtha ina branar gan dabht ina gnáthóg d’fheithidí, fiadhúlra agus fásra nádúrtha.",
        continueButtonLabel: "Continue",
        continueAlertTitle: "Finished?",
        continueAlertText_100perc: "Are you finished with your land use allocation?",
        continueAlertText_below100perc: "You did not use 100% of the land. You can either edit the landscape to represent your preferences or the remaining space will be added to the land use \"biodiversity\".",
        continueAlertYes: "Yes",
        continueAlertChange: "No, I want to change something",
        selectAtLeastOneText: "Please select your desired landscape",
        selectAtLeastOneTitle: "Error",
      },
      Warnings: {
        minBiodiversity: "Nótáil le do thoil go gcuimsíonn an úsáid talún seo gnáthóga d’éin, feithidí agus plandaí éagsúla. Coigeartófar an bhithéagsúlacht go dtí leibhéal 1 go huathoibríoch má athraíonn tú aon cheann de na húsáidí eile talún ó 0 go dtí leibhéal níos airde.",
        above100Perc: "Ní ceadaithe duit an leibhéal seo a roghnú mar go sáraíonn sé an cumhdach spásúil de 100%. Ísligh le do thoil leibhéal amháin de na leibhéil eile de réir do chuid roghanna agus laistigh de na teorainneacha spásúla."
      },
      DimensionNames: {
        farmland: "Talamh feirme",
        renewable: "Fuinnimh In-athnuaite",
        agroforestry: "Agrafhoraoiseacht",
        biodiversity: "An Bhithéagsúlacht",
      },
      DimensionExplanations: {
        agroforestry: "Comhcheanglaítear anseo leis an agrafhoraoiseacht fás crann agus barraí ar an achar céanna talún. Soláthraítear leis an úsáid seo talún sochair éagsúla don chomhshaol, mar go gcuireann siad le hoiriúnú don athrú aeráide agus dona mhaolú trí charbón a stóráil sa stoc, sna craobhacha agus sna fréamhacha, agus go gcosnaíonn siad an ithir mar aon le cur leis an mbithéagsúlacht trí ghnáthóga a chur ar fáil d’fheithidí, éin agus fiadhúlra eile.",
        agriculture: "Sa chás seo bainistítear talamh talmhaíochta mar ghnáth-thalamh feirme le hionchur meánach de leasacháin agus lotnaidicídí. Áiríonn na cleachtais bainistíochta ithreach an churaíocht. Is olc an tionchar a bhíonn ag tiontú cumhdach nádúrtha talún ina thalamh feirme ar bhithéagsúlacht ithreach tírdhreacha toisc go lagaíonn brúnna meicniúla agus cur chun feidhme agraiceimiceán gnáthóga agus foinsí bia d’fheithidí, éin, agus fiadhúlra eile.",
        biodiversity: "Cruthaíonn eilimintí nádúrtha den tírdhreach, mar shampla, imill bláfara páirceanna, crainn agus fálta sceach aonair, gnáthóga i gcomhair éan tailte feirme, feithidí agus fiadhúlra. Soláthraíonn na heilimintí seo den tírdhreach bia agus dídean do phlandaí, feithidí, éin agus mamaigh bheaga i gcaitheamh na bliana.",
        renewable: "Tá an comhchodanna den tírdhreach a chuireann fuinneamh in-athnuaite ar fáil sa chás seo léirithe le painéil ghréine agus tuirbíní gaoithe, agus faoina bhun tá an ithir clúdaithe le féarach nádúrtha. Tá an-chuid sochair fhéideartha ag soláthar an fhuinnimh in-athnuaite, mar shampla, laghdú ar astuithe gáis cheaptha, spleáchas laghdaithe ar bhreosla iontaiseach, agus cruthú jabanna sa dúiche. Bíonn dea-thionchar ag an talamh féarach neamhbhearnaithe faoi na tuirbíní gaoithe ar bhithéagsúlacht (na hithreach). Féadann teicneolaíochtaí an fhuinnimh in-athnuaite agus úsáidí eile talún bheith comhoiriúnach dá chéile. Anseo thíos, ar mhaithe leis an simpliú, cuir in iúl le do thoil do chéadroghanna ar an mbonn tuisceana go mbeidh eisiamh spásúil ann.",
      },
      GroundView: {
        title: "Soil biodiversity",
        text: "Tá ithreacha agus bithéagsúlacht ithreach déanta suas de na milliúin speiceas miocróbach agus ainmhíoch, mar shampla, baictéir, fungais, ciaróga agus péisteanna talún. Fágann treisiú na n-úsáidí éagsúla talún iarsmaí éagsúla ar ithreacha agus ar an mbithéagsúlacht ithreach. Mar shampla, sa talmhaíocht, oibríonn na brúnna meicniúla atá ag dul i méid, mar aon le húsáid leasachán, droch-thionchair láidre ar bhithéagsúlacht agus feidhmiú na hithreach. I gcontrárthacht leis sin, tá sé faighte amach go n-oibríonn bithéagsúlacht a bhíonn ag dul i méid (os cionn talún) dea-thionchair agus mar an gcéanna leis na heilimintí tírdhreacha a ghabhann in éineacht leis sin.",
        choiceEffect_negative: "Maidir le do rogha de chomhchodanna tírdhreacha, tá tionchar diúltach aige ar bhithéagsúlacht na hithreach.",
        choiceEffect_slightlypositive: "Maidir le do rogha de chomhchodanna tírdhreacha, tá tionchar beagán dearfach aige ar bhithéagsúlacht na hithreach.",
        choiceEffect_positive: "Maidir le do rogha de chomhchodanna tírdhreacha, tá tionchar dearfach aige ar bhithéagsúlacht na hithreach.",
        cantGoBackHeader: "You cannot go back",
        cantGoBackText: "You cannot change your landscape preferences anymore. Please proceed to the next Questions.",
        continueToSurveyButton: "Continue",
      }
    }
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;