
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const DimensionWrapper = styled.div`
max-width: 1000px;
padding-right: 50px
`;
const OuterWrapper = styled.div`
width:100%;
max-width: 1200px;`;
export const Header = () => {
  const { t } = useTranslation();

  return <OuterWrapper><DimensionWrapper>
    <Typography variant="h2">{t("MainView.title")}</Typography>
    <Typography variant="body1" gutterBottom>{t("MainView.description")}</Typography>
    <Typography variant="body1" gutterBottom>{t("MainView.note")}    </Typography></DimensionWrapper>
  </OuterWrapper>
};