import React, { useContext } from "react";
import { useCountry } from "./useCountry";
import { ValuesContext } from ".";
const images = {
  dk: {
    agriculture: [require("./images/landscape/dk/agriculture/agriculture-1.png"),
    require("./images/landscape/dk/agriculture/agriculture-2.png"),
    require("./images/landscape/dk/agriculture/agriculture-3.png"),
    require("./images/landscape/dk/agriculture/agriculture-4.png"),
    require("./images/landscape/dk/agriculture/agriculture-5.png")],

    agroforest: [require("./images/landscape/dk/agroforest/agroforest-1.png"),
    require("./images/landscape/dk/agroforest/agroforest-2.png"),
    require("./images/landscape/dk/agroforest/agroforest-3.png"),
    require("./images/landscape/dk/agroforest/agroforest-4.png"),
    require("./images/landscape/dk/agroforest/agroforest-5.png"),
    ],
    biodiversity: [require("./images/landscape/dk/biodiversity/biodiversity-1.png"),
    require("./images/landscape/dk/biodiversity/biodiversity-2.png"),
    require("./images/landscape/dk/biodiversity/biodiversity-3.png"),
    require("./images/landscape/dk/biodiversity/biodiversity-4.png"),
    require("./images/landscape/dk/biodiversity/biodiversity-5.png"),
    ],
    renewables: [
      require("./images/landscape/dk/renewables/renewables-1.png"),
      require("./images/landscape/dk/renewables/renewables-2.png"),
      require("./images/landscape/dk/renewables/renewables-3.png"),
      require("./images/landscape/dk/renewables/renewables-4.png"),
      require("./images/landscape/dk/renewables/renewables-5.png"),
    ]
  },
  es: {
    agriculture: [require("./images/landscape/es/agriculture/agriculture-1.png"),
    require("./images/landscape/es/agriculture/agriculture-2.png"),
    require("./images/landscape/es/agriculture/agriculture-3.png"),
    require("./images/landscape/es/agriculture/agriculture-4.png"),
    require("./images/landscape/es/agriculture/agriculture-5.png"),
    ],
    agroforest: [require("./images/landscape/es/agroforest/agroforest-1.png"),
    require("./images/landscape/es/agroforest/agroforest-2.png"),
    require("./images/landscape/es/agroforest/agroforest-3.png"),
    require("./images/landscape/es/agroforest/agroforest-4.png"),
    require("./images/landscape/es/agroforest/agroforest-5.png"),
    ],
    biodiversity: [require("./images/landscape/es/biodiversity/biodiversity-1.png"),
    require("./images/landscape/es/biodiversity/biodiversity-2.png"),
    require("./images/landscape/es/biodiversity/biodiversity-3.png"),
    require("./images/landscape/es/biodiversity/biodiversity-4.png"),
    require("./images/landscape/es/biodiversity/biodiversity-5.png"),
    ], renewables: [
      require("./images/landscape/es/renewables/renewables-1.png"),
      require("./images/landscape/es/renewables/renewables-2.png"),
      require("./images/landscape/es/renewables/renewables-3.png"),
      require("./images/landscape/es/renewables/renewables-4.png"),
      require("./images/landscape/es/renewables/renewables-5.png"),
    ]
  },
  ie: {
    agriculture: [require("./images/landscape/ie/agriculture/agriculture-1.png"),
    require("./images/landscape/ie/agriculture/agriculture-2.png"),
    require("./images/landscape/ie/agriculture/agriculture-3.png"),
    require("./images/landscape/ie/agriculture/agriculture-4.png"),
    require("./images/landscape/ie/agriculture/agriculture-5.png"),
    ],
    agroforest: [require("./images/landscape/ie/agroforest/agroforest-1.png"),
    require("./images/landscape/ie/agroforest/agroforest-2.png"),
    require("./images/landscape/ie/agroforest/agroforest-3.png"),
    require("./images/landscape/ie/agroforest/agroforest-4.png"),
    require("./images/landscape/ie/agroforest/agroforest-5.png"),
    ],
    biodiversity: [
      require("./images/landscape/ie/biodiversity/biodiversity-1.png"),
      require("./images/landscape/ie/biodiversity/biodiversity-2.png"),
      require("./images/landscape/ie/biodiversity/biodiversity-3.png"),
      require("./images/landscape/ie/biodiversity/biodiversity-4.png"),
      require("./images/landscape/ie/biodiversity/biodiversity-5.png"),
    ], renewables: [
      require("./images/landscape/ie/renewables/renewables-1.png"),
      require("./images/landscape/ie/renewables/renewables-2.png"),
      require("./images/landscape/ie/renewables/renewables-3.png"),
      require("./images/landscape/ie/renewables/renewables-4.png"),
      require("./images/landscape/ie/renewables/renewables-5.png"),
    ]
  }
}




const baseimages = { dk: require("./images/landscape/dk/base.png"), es: require("./images/landscape/es/base.png"), ie: require("./images/landscape/ie/base.png") }


const calcImages = (value: number, images: any[]) => {
  return images.filter((_, index) => {
    if (index < value) {
      return true;
    }
    return false;
  });
}
export const LandscapeView = () => {
  const {
    values
  } = useContext(ValuesContext);
  const country = useCountry();
  const agricultureImagesToRender = calcImages(values.agriculture, images[country].agriculture);
  const agroforestImagesToRender = calcImages(values.agroforestry, images[country].agroforest);
  const biodiversityImagesToRender = calcImages(values.biodiversity, images[country].biodiversity);
  const renewablesImagesToRender = calcImages(values.renewable, images[country].renewables);

  console.log(agricultureImagesToRender.length)
  return <div style={{ position: "relative", top: 0, left: 0 }}>
    <img src={baseimages[country]} width="100%" style={{ position: "relative", top: 0, left: 0 }} />
    {agricultureImagesToRender.map((image, index) => <img src={image} key={`agricultureimage_${index}`} width="100%" style={{ position: "absolute", top: 0, left: 0 }} />)}
    {agroforestImagesToRender.map((image, index) => <img src={image} key={`agroforestimage_${index}`} width="100%" style={{ position: "absolute", top: 0, left: 0 }} />)}
    {renewablesImagesToRender.map((image, index) => <img src={image} key={`renewablesimage_${index}`} width="100%" style={{ position: "absolute", top: 0, left: 0 }} />)}
    {biodiversityImagesToRender.map((image, index) => <img src={image} key={`biodiversityimage_${index}`} width="100%" style={{ position: "absolute", top: 0, left: 0 }} />)}
  </div>



};
