import React, { createContext, useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import './i18n';



import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import { GroundView } from "./GroundView";

export const ValuesContext = createContext({ values: { agriculture: 0, agroforestry: 0, biodiversity: 0, renewable: 0 }, setValues: (args: { agriculture: number, agroforestry: number, biodiversity: number, renewable: number }) => { } });


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "ground",
    element: <GroundView />,
  },
]);


const ContextWrapper = () => {
  const [values, setValues] = useState({ agriculture: 0, agroforestry: 0, biodiversity: 0, renewable: 0 });
  return (
    <ValuesContext.Provider
      value={{
        values,
        setValues
      }}
    >
      <RouterProvider router={router} />
    </ValuesContext.Provider>
  );
}


const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ContextWrapper />
  </React.StrictMode>
);
