import { useSearchParams } from "react-router-dom";
import i18n from './i18n'

const lngLookup = {
  dk: "da",
  ie: "ie",
  es: "es",
  ie_en: "en",
}
export const useCountry = (): "dk" | "ie" | "es" => {
  const [searchParams] = useSearchParams();
  const cc = searchParams.get("country");
  let lng: "dk" | "ie" | "es" | "ie_en" = "ie_en";
  if (cc === "ie" || cc === "dk" || cc === "es") {
    lng = cc;
  }
  if (i18n.language !== lngLookup[lng]) {
    i18n.changeLanguage(lngLookup[lng])
  }
  if (lng === "ie_en") {
    lng = "ie";
  }
  return lng;
}