import styled from "styled-components";

import React, { useState } from "react";
import { LandscapeView } from "./LandscapeView";
import { Controls } from "./Controls";
import { Header } from "./Header";
import { Alert, IconButton, Snackbar, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useCountry } from "./useCountry";


const MarginWrapper = styled.div`
  margin: 20px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 100vh;
  max-width: 100%;
`;

const ImageControlsWrapper = styled.div`
  display: flex;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
  @media (min-width: 1000px) {
    flex-direction: row;
  }
  align-items: center;
  justify-content: center;
  max-width: 1600px;
  flex-wrap: wrap;
`;
const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 1000px;
  @media (min-width: 1000px) {
    padding-right: 40px;
  }
`;
const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 350px;
`;


function App() {
  //Make sure component rerenders on language change
  const country = useCountry();
  const [warnings, setWarnings] = useState<string[]>([]);

  return (
    <MarginWrapper>
      <Wrapper>
        <Header />

        {warnings.map((warning, index) => (
          <Snackbar
            key={index}
            open={warnings.length > 0}

            onClose={() => setWarnings([])}
            sx={{ maxWidth: 800 }}
          >
            <Alert icon={false} severity="warning">{warning}</Alert>
          </Snackbar>

        ))}

        <ImageControlsWrapper>
          <ImagesWrapper>
            <LandscapeView />
            {/*<GroundView farm={farm} renewable={renewable} agroforestry={agroforestry} biodiversity={biodiversity} />*/}
          </ImagesWrapper>
          <ControlsWrapper>
            <Controls setWarnings={setWarnings} />
          </ControlsWrapper>
        </ImageControlsWrapper>
      </Wrapper>
    </MarginWrapper>
  );
}

export default App;
