import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Popover } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useContext, useState } from "react";
import styled from "styled-components";
import { ValuesContext } from ".";
import InfoIcon from "@mui/icons-material/Info";
import { useCountry } from "./useCountry";
import { useUserId } from "./useUserId";
import { useTranslation } from "react-i18next";
import axios from "axios";

const DimensionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
  width: 100%;
`;
const CategoryTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  `;

const percentageDict = [0, 0.1, 0.2, 0.4, 0.6, 0.8];


export const Controls = ({ setWarnings }: { setWarnings: (val: string[]) => void }) => {
  const { values, setValues } = useContext(ValuesContext);
  const { t } = useTranslation();

  const matrix = {
    agriculture: {
      agriculture: [0, 1, 2, 3, 4, 5],
      biodiversity: [5, 5, 5, 4, 3, 2],
      renewable: [5, 5, 4, 3, 2, 1],
      agroforestry: [5, 5, 4, 3, 2, 1],
    },
    biodiversity: {
      agriculture: [0, 5, 5, 4, 3, 2],
      biodiversity: [0, 1, 2, 3, 4, 5],
      renewable: [0, 5, 5, 4, 3, 2],
      agroforestry: [0, 5, 5, 4, 3, 2],
    },
    renewable: {
      agriculture: [5, 5, 4, 3, 2, 1],
      biodiversity: [5, 5, 5, 4, 3, 2],
      renewable: [0, 1, 2, 3, 4, 5],
      agroforestry: [5, 5, 4, 3, 2, 1],
    },
    agroforestry: {
      agroforestry: [0, 1, 2, 3, 4, 5],
      agriculture: [5, 5, 4, 3, 2, 1],
      biodiversity: [5, 5, 5, 4, 3, 2],
      renewable: [5, 5, 4, 3, 2, 1],
    },
  }; //as { [keys: DimensionName]: { [keys: DimensionName]: number[] } }


  const dimensionTexts = t("DimensionExplanations", { returnObjects: true }) as { [keys: string]: string };

  type DimensionName =
    | "agriculture"
    | "biodiversity"
    | "renewable"
    | "agroforestry";

  const createSetValue = (dimensionName: DimensionName) => {
    return (value: number) => {
      setWarnings([]);
      if (value === values[dimensionName]) {
        return;
      }
      const constraints = matrix[dimensionName];
      const otherDimensionNames = Object.keys(constraints) as DimensionName[];
      for (const otherDimension of otherDimensionNames) {
        if (otherDimension === dimensionName) {
          continue;
        }
        if (constraints[otherDimension][value] < values[otherDimension]) {
          console.log(
            "error",
            value,
            otherDimension,
            constraints[otherDimension][value],
            values[otherDimension]
          );
          setWarnings([
            t("Warnings.above100Perc"),
          ]);
          //TODO: show error
          return;
        }
      }

      const newValues = { ...values, [dimensionName]: value };
      if (dimensionName !== "biodiversity" && value > 0 && values.biodiversity === 0) {
        newValues.biodiversity = 1;
        setWarnings([t("Warnings.minBiodiversity")])
      }

      let usedPercentage = 0;
      for (const dimension of Object.keys(newValues)) {
        usedPercentage += percentageDict[newValues[dimension as DimensionName]];
      }
      console.log("usedPercentage", usedPercentage)
      if (usedPercentage > 1) {
        setWarnings([
          t("Warnings.above100Perc"),
        ]);
      }
      else {
        setValues(newValues);
      }
    };
  };

  const dimensions: {
    key: "agriculture" | "renewable" | "agroforestry" | "biodiversity";
    name: string;
    value: number;
    setter: (value: number) => void;
  }[] = [
      {
        key: "agriculture",
        name: t("DimensionNames.farmland"),
        value: values.agriculture,
        setter: createSetValue("agriculture"),
      },
      {
        key: "renewable",
        name: t("DimensionNames.renewable"),
        value: values.renewable,
        setter: createSetValue("renewable"),
      },
      {
        key: "agroforestry",
        name: t("DimensionNames.agroforestry"),
        value: values.agroforestry,
        setter: createSetValue("agroforestry"),
      },
      {
        key: "biodiversity",
        name: t("DimensionNames.biodiversity"),
        value: values.biodiversity,
        setter: createSetValue("biodiversity"),
      },
    ];

  const [anchorEl, setAnchorEl] = useState<{
    agriculture: HTMLElement | null,
    renewable: HTMLElement | null,
    agroforestry: HTMLElement | null,
    biodiversity: HTMLElement | null
  }
  >({ agriculture: null, renewable: null, agroforestry: null, biodiversity: null });

  const createHandlePopoverOpen = (key: DimensionName) => {
    return (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl({ ...anchorEl, [key]: event.currentTarget });
    };
  };

  const createHandlePopoverClose = (key: DimensionName) => {
    return () => {
      setAnchorEl({ ...anchorEl, [key]: null });
    };
  };
  const navigate = useNavigate();



  const open = {
    agriculture: Boolean(anchorEl.agriculture),
    renewable: Boolean(anchorEl.renewable),
    agroforestry: Boolean(anchorEl.agroforestry),
    biodiversity: Boolean(anchorEl.biodiversity)
  };
  const country = useCountry();
  const [nextDialogOpen, setNextDialogOpen] = useState(false);
  const [selectAtLeastOneDialogOpen, setSelectAtLeastOneDialogOpen] = useState(false);


  const possibleValues = [0, 1, 2, 3, 4, 5];
  const userId = useUserId();
  const below100Perc = percentageDict[values.agriculture] + percentageDict[values.renewable] + percentageDict[values.agroforestry] + percentageDict[values.biodiversity] < 1;
  return (
    <>
      {dimensions.map((dimension) => {
        return (
          <DimensionWrapper>

            <CategoryTitleWrapper>
              <Typography
                variant="h6"
              >
                {dimension.name}
              </Typography> <Typography sx={{ marginLeft: 1 }} aria-owns={open ? `mouse-over-popover${dimension.key}` : undefined}
                aria-haspopup="true"
                onMouseEnter={createHandlePopoverOpen(dimension.key)}
                onMouseLeave={createHandlePopoverClose(dimension.key)}
              ><InfoIcon fontSize="small" color="primary" style={{ verticalAlign: "middle" }} /></Typography>

              <Popover
                id={`mouse-over-popover${dimension.key}`}
                sx={{
                  pointerEvents: "none",

                }}
                open={open[dimension.key]}
                anchorEl={anchorEl[dimension.key]}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={createHandlePopoverClose(dimension.key)}
                disableRestoreFocus
              >
                <Typography sx={{ p: 1, maxWidth: 500 }}>
                  {dimensionTexts[dimension.key]}
                </Typography>
              </Popover>
            </CategoryTitleWrapper>
            <ButtonWrapper>
              {possibleValues.map((value) => {
                return (
                  <Button
                    sx={{ height: 50, padding: 0, minWidth: 50, margin: 0 }}
                    onClick={() => dimension.setter(value)}
                    variant={
                      value === dimension.value ? "contained" : "outlined"
                    }
                  >
                    {" "}
                    {value}
                  </Button>
                );
              })}
            </ButtonWrapper>
          </DimensionWrapper>
        );
      })}


      <Dialog
        open={selectAtLeastOneDialogOpen}
        onClose={() => setSelectAtLeastOneDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("MainView.selectAtLeastOneTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("MainView.selectAtLeastOneText")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={async () => {
            setSelectAtLeastOneDialogOpen(false)
          }}>  OK</Button>

        </DialogActions>
      </Dialog >
      <Dialog
        open={nextDialogOpen}
        onClose={() => setNextDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("MainView.continueAlertTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("MainView.continueAlertText", { context: below100Perc ? "below100perc" : "100perc" })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={async () => {

            if (userId) {
              try {
                const response = await axios.post('https://answers2023.landpref.org/store',
                  {
                    "id": userId,
                    "agriculture": values.agriculture,
                    "agroforestry": values.agroforestry,
                    "renewable": values.renewable,
                    "biodiversity": values.biodiversity,
                  }, {
                  headers: {
                    'Content-Type': 'application/json'
                  }
                })
                if (response.status === 200) {
                  navigate(`ground?extid=${userId}&country=${country}`)
                }
                else {
                  alert("Could not save data. Server error");
                  console.log(response);
                }
              }
              catch (e) {
                console.log("Axios error", e);
                alert("Could not save data. Network error");
              }
            } else {
              navigate(`ground?extid=${userId}&country=${country}`)
            }
          }
          }>  {t("MainView.continueAlertYes")}</Button>
          <Button onClick={() => setNextDialogOpen(false)} autoFocus>
            {t("MainView.continueAlertChange")}
          </Button>
        </DialogActions>
      </Dialog >

      <Button variant="contained" sx={{ marginTop: 3 }} fullWidth onClick={() => {

        if (values.agriculture + values.renewable + values.agroforestry + values.biodiversity === 0) {

          setSelectAtLeastOneDialogOpen(true);
        } else {
          setNextDialogOpen(true)
        }
      }
      }>Continue</Button>
    </>
  );
};
