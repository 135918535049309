import React, { useContext, useState } from "react";
import { ValuesContext } from ".";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import styled from "styled-components";
import { unstable_useBlocker } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUserId } from "./useUserId";
const ground1 = require("./images/ground/1.png");
const ground2 = require("./images/ground/2.png");
const ground3 = require("./images/ground/3.png");

const OuterWrapper = styled.div`
height: 100%;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
padding:20px 20px 10px 10px;
max-width: 1000px;
margin:0 auto;




`;

const TextAndImageWrapper = styled.div`
max-height: 90vh;
overflow-y: scroll;
// scrollbar-width: none;
// -ms-overflow-style: none;
// ::-webkit-scrollbar{
//   display: none;
// }
`;


const computeGroundImage = ({ agriculture, renewable, agroforestry, biodiversity }: { agriculture: number, renewable: number, agroforestry: number, biodiversity: number }) => {

  const lookup = {
    agriculture: [0, 2, 1, 0, -1, -2],
    biodiversity: [0, 4, 8, 16, 24, 32],
    renewable: [0, 2, 4, 8, 12, 16],
    agroforestry: [0, 3, 6, 12, 18, 24]
  }
  const percentages = [0, 0.1, 0.2, 0.4, 0.6, 0.8];

  const usedPercentage = percentages[agriculture] + percentages[renewable] + percentages[agroforestry];
  const biodivPerc = 1 - usedPercentage;
  let biodiversityMax = 0;
  for (let i = 0; i < percentages.length; i++) {
    if (percentages[i] <= biodivPerc) {
      biodiversityMax = i;
    }
  }

  const score = lookup.agriculture[agriculture] + lookup.renewable[renewable] + lookup.agroforestry[agroforestry] + lookup.biodiversity[biodiversityMax];

  if (score < 16) {
    return { effect: "negative", image: ground1 };
  }
  if (score < 29) {
    return { effect: "slightlypositive", image: ground2 };
  }
  return { effect: "positive", image: ground3 };
}
export const GroundView = () => {

  const [alertOpen, setAlertOpen] = useState(false);
  unstable_useBlocker(() => {
    setAlertOpen(true)
    return true
  });



  const {
    values
  } = useContext(ValuesContext);
  const { effect, image } = computeGroundImage(values)
  const { t } = useTranslation();
  const userId = useUserId();

  return <OuterWrapper><TextAndImageWrapper>

    <Typography variant="h4">{t("GroundView.title")}</Typography>
    <Typography paddingY={3}>{t("GroundView.text")}
    </Typography>
    <Typography variant="subtitle1" paddingBottom={3}>{t("GroundView.choiceEffect", { context: effect })}</Typography>


    <img src={image} width="100%" style={{ borderRadius: 4 }} />
    <Dialog
      open={alertOpen}
      onClose={() => setAlertOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("GroundView.cantGoBackHeader")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("GroundView.cantGoBackText")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setAlertOpen(false)}>Ok</Button>

      </DialogActions>
    </Dialog>
  </TextAndImageWrapper >
    <Box pt={2} width="100%" maxWidth={1000}>
      <Button variant="contained" fullWidth onClick={() => {
        if (userId) {
          //https://v4-3-7.de3.surveyengine.com/ 
          //https://v4-4-3.saas-us1.surveyengine.com/bin/play.pl
          window.location.href = `https://v4-3-7.de3.surveyengine.com/bin/play.pl?page_number=26&PLAYSID=${userId}`
        }
      }}>{t("GroundView.continueToSurveyButton")}</Button>
    </Box>
  </OuterWrapper >;
};
